define("discourse/plugins/discourse-github-verification/discourse/templates/preferences/github", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group github-username">
    <label class="control-label" for="edit-github-username">
      {{i18n "discourse_github_verification.username"}}
    </label>
    <div class="controls">
      {{#if this.githubUsername}}
        <div class="github-username-control-wrapper">
          <GithubUserLink @username={{this.githubUsername}} />
          <DButton
            @action={{this.clear}}
            @icon="trash-can"
            @title="discourse_github_verification.clear"
            class="btn-secondary clear-github-verification-btn"
          />
        </div>
        <p class="instructions">
          {{i18n "discourse_github_verification.instructions.clear"}}
        </p>
      {{else}}
        {{#if (eq this.currentUser.id this.model.id)}}
          <DButton
            @action={{this.login}}
            @icon="fab-github"
            @label="discourse_github_verification.connect"
            class="btn-primary github-verification-btn"
          />
          <p class="instructions">
            {{i18n "discourse_github_verification.instructions.connect"}}
          </p>
        {{else}}
          <p>
            {{i18n
              "discourse_github_verification.instructions.cannot_connect_for_others"
            }}
          </p>
        {{/if}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "tJUxd8LY",
    "block": "[[[10,0],[14,0,\"control-group github-username\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"edit-github-username\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"discourse_github_verification.username\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"githubUsername\"]],[[[1,\"      \"],[10,0],[14,0,\"github-username-control-wrapper\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@username\"],[[30,0,[\"githubUsername\"]]]],null],[1,\"\\n        \"],[8,[39,3],[[24,0,\"btn-secondary clear-github-verification-btn\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"clear\"]],\"trash-can\",\"discourse_github_verification.clear\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"instructions\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"discourse_github_verification.instructions.clear\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,0,[\"currentUser\",\"id\"]],[30,0,[\"model\",\"id\"]]],null],[[[1,\"        \"],[8,[39,3],[[24,0,\"btn-primary github-verification-btn\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"login\"]],\"fab-github\",\"discourse_github_verification.connect\"]],null],[1,\"\\n        \"],[10,2],[14,0,\"instructions\"],[12],[1,\"\\n          \"],[1,[28,[35,0],[\"discourse_github_verification.instructions.connect\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,0],[\"discourse_github_verification.instructions.cannot_connect_for_others\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"if\",\"github-user-link\",\"d-button\",\"eq\"]]",
    "moduleName": "discourse/plugins/discourse-github-verification/discourse/templates/preferences/github.hbs",
    "isStrictMode": false
  });
});